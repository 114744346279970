export default defineComponent({
  props: {
    title: {
      type: String,
      "default": "North America"
    },
    subtitle: {
      type: String,
      "default": ""
    },
    detailArr: {
      type: Array,
      "default": function _default() {
        return [{
          value: "15,000+",
          text: "Stocks"
        }, {
          value: "10",
          text: "Exchanges"
        }, {
          value: "3",
          text: "Countries"
        }];
      }
    },
    subdetailArr: {
      type: Array,
      "default": function _default() {
        return [{
          title: "Countries:",
          text: "USA, Canada"
        }, {
          title: "Exchanges:",
          text: "NYSE, NASDAQ, OTC"
        }];
      }
    }
  },
  data: function data() {
    return {
      showDetails: false
    };
  }
});